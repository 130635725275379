import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useRouter } from 'next/router';

import useAuthentication from '@/components/Authentication/useAuthentication';
import Article from '@/components/LandingMozza/common/Article/Article';
import CertifiedTeachers from '@/components/LandingMozza/common/CertifiedTeachers/CertifiedTeachers';
import Fight from '@/components/LandingMozza/common/Fight/Fight';
import Hero from '@/components/LandingMozza/common/Hero/Hero';
import useGoToPageWithEvent from '@/components/LandingMozza/common/hooks/useGoToPageWithEvent';
import LandingFaq from '@/components/LandingMozza/common/LandingFaq/LandingFaq';
import Methodology from '@/components/LandingMozza/common/Methodology/Methodology';
import PricingSection from '@/components/LandingMozza/common/PricingSection/PricingSection';
import RaiseIncome from '@/components/LandingMozza/common/RaiseIncome/RaiseIncome';
import Reviews from '@/components/LandingMozza/common/Reviews/Reviews';
import SeoText from '@/components/LandingMozza/common/SeoText/SeoText';
import StartingSteps from '@/components/LandingMozza/common/StartingSteps/StartingSteps';
import SeenOn from '@/components/LandingMozza/LandingMozza/SeenOn/SeenOn';
import SeenOnSafari from '@/components/LandingMozza/LandingMozza/SeenOn/SeenOnSafari/SeenOnSafari';
import SeoLinksLandingMozza from '@/components/LandingMozza/LandingMozza/SeoLinksLandingMozza/SeoLinksLandingMozza';
import Subjects from '@/components/LandingMozza/LandingMozza/Subjects/Subjects';
import MarketingBanner from '@/components/MarketingBanner/MarketingBanner';
import PaddingContainer from '@/components/PaddingContainer/PaddingContainer';
import { PRIVATE_COURSE, SEARCH } from '@/data/navbar/navButtons';
import { useAbTestHero } from '@/shared/Hooks/abTests/useAbTestHero';
import { useIsMobile } from '@/utils/useResponsive';

import useStyles from './styles';

const LandingMozza: FC = () => {
  const { classes } = useStyles();
  const goToPageWithEvent = useGoToPageWithEvent();
  const isMobile = useIsMobile();
  const { authentication } = useAuthentication();
  const [bannerRoot, setBannerRoot] = useState<HTMLElement>();
  const { query } = useRouter();
  const { scrollTo } = query;
  const fightsRef = useRef<HTMLDivElement>(null);
  const stepsRef = useRef<HTMLDivElement>(null);
  const [isSafari, setIsSafari] = useState(false);
  const { isAbTestHeroLoaded, isAbTestHeroWithoutPictureVariant } =
    useAbTestHero();

  useEffect(() => {
    setBannerRoot(document.getElementById('banner-root'));
    if (fightsRef && scrollTo === 'fights') {
      fightsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (stepsRef && scrollTo === 'steps') {
      stepsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollTo]);

  useEffect(() => {
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }, []);

  const canShowMarketingBanner = useMemo(() => {
    const isNotAuthenticated = authentication?.authData
      ? !authentication.isAuthenticated
      : false;
    return bannerRoot && isNotAuthenticated;
  }, [authentication, bannerRoot]);

  return (
    <>
      <div
        style={{
          opacity: isAbTestHeroLoaded ? 1 : 0,
          transition: 'opacity 0.3s',
        }}
      >
        <Article
          backgroundColor={
            isAbTestHeroWithoutPictureVariant
              ? 'greenGradientAbTest'
              : 'greenGradient'
          }
          verticalPadding={64}
          className={
            isAbTestHeroWithoutPictureVariant && classes.abHeroContainer
          }
        >
          <PaddingContainer>
            <Hero />
          </PaddingContainer>
        </Article>
      </div>
      {isSafari ? (
        <Article backgroundColor="lightGrey" verticalPadding={0}>
          <PaddingContainer>
            <SeenOnSafari />
          </PaddingContainer>
        </Article>
      ) : (
        <SeenOn />
      )}
      <div ref={fightsRef}>
        <Article backgroundColor="white">
          <Fight />
        </Article>
      </div>
      <Article backgroundColor="blue">
        <PaddingContainer>
          <PricingSection
            isMobile={isMobile}
            handleRedirection={() =>
              goToPageWithEvent({
                route: SEARCH,
                event: 'searchTeacher_landingFaq',
              })
            }
          />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white">
        <PaddingContainer>
          <Methodology
            handleRedirection={() =>
              goToPageWithEvent({
                route: SEARCH,
                event: 'searchTeacher_landingMethodology',
              })
            }
          />
        </PaddingContainer>
      </Article>
      <div ref={stepsRef}>
        <Article backgroundColor="white" withOverflowHidden={false}>
          <PaddingContainer>
            <StartingSteps
              handleRedirection={() =>
                goToPageWithEvent({
                  route: SEARCH,
                  event: 'findTeacher_landingStepper',
                })
              }
            />
          </PaddingContainer>
        </Article>
      </div>
      <Article backgroundColor="white">
        <PaddingContainer>
          <Reviews
            handleRedirection={() =>
              goToPageWithEvent({
                route: SEARCH,
                event: 'findTeacher_landingReviews',
              })
            }
          />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="green">
        <PaddingContainer>
          <Subjects
            isMobile={isMobile}
            handleRedirection={(query, event: string) =>
              goToPageWithEvent({
                route: SEARCH,
                event,
                query,
              })
            }
          />
        </PaddingContainer>
      </Article>
      <Article
        backgroundColor="white"
        itemScope
        itemType="https://schema.org/FAQPage"
        verticalPadding={64}
      >
        <PaddingContainer>
          <LandingFaq
            handleRedirection={() =>
              goToPageWithEvent({
                route: SEARCH,
                event: 'searchTeacher_landingFaq',
              })
            }
          />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white" verticalPadding={64}>
        <PaddingContainer>
          <CertifiedTeachers
            isMobile={isMobile}
            onGoToSearchPage={() =>
              goToPageWithEvent({
                route: SEARCH,
                event: 'searchTeacher_landingCertifiedTeacher',
              })
            }
          />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white" verticalPadding={64}>
        <PaddingContainer>
          <RaiseIncome privateCoursePageURL={PRIVATE_COURSE} />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="green" verticalPadding={64}>
        <PaddingContainer>
          <SeoLinksLandingMozza />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white">
        <PaddingContainer>
          <SeoText />
        </PaddingContainer>
      </Article>
      {canShowMarketingBanner && createPortal(<MarketingBanner />, bannerRoot)}
    </>
  );
};

export default LandingMozza;
