import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  abHeroContainer: {
    maxWidth: rem(1280),
    borderRadius: rem(16),
    margin: `${rem(30)} ${rem(8)} ${rem(60)}`,
    [breakpoints.up('sm')]: {
      margin: `${rem(40)} ${rem(8)} ${rem(60)}`,
    },
    // Temporary for AB test (lg is 1024 and not 1280)
    [breakpoints.up(1296)]: {
      margin: `${rem(40)} auto ${rem(60)}`,
    },
  },
}));
